import React from 'react'
import Img from 'gatsby-image'
import Layout from '../layouts/index'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'

const Template = ({ data, pageContext }) => {
  const { markdownRemark: post } = data
  const { frontmatter, html } = post
  const { title, date, tags, image, description } = frontmatter
  const { next, prev } = pageContext

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.description} />
      </Helmet>
      <div className="blogpost">
        <div className="article-header">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-7-fullhd is-9-widescreen is-10-tablet">
                <div className="content is-medium is-blog">
                  <h1 className="title is-spaced">{frontmatter.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="article-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-7-fullhd is-9-widescreen is-10-tablet">
                <div
                  className="content is-medium is-blog"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
                <div className="content is-medium is-blog">
                  <h2 id="about-the-author">About Me</h2>
                  <p>
                    I am a self-taught developer who is passionate about
                    software development, entrepreneurship and finance. I love
                    to write about my daily life at work and outside work.
                  </p>
                  <p>
                    You can find more about me on &nbsp;
                    <a href="https://www.linkedin.com/in/kennly/">
                      Kenneth Au LinkedIn
                    </a>
                  </p>
                </div>
                <div className="columns">
                  <div className="column is-2 subtitle is-4">
                    {prev && <Link to={prev.frontmatter.path}>Previous</Link>}
                  </div>
                  <div className="column is-8" />
                  <div className="column is-2 subtitle is-4">
                    {next && <Link to={next.frontmatter.path}>Next</Link>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        path
        tags
        excerpt
        description
      }
    }
  }
`

export default Template
